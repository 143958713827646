.guess-list {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.empty-guess {
  background-color: #f6f4f4;
  padding: 10px;
  border-radius: 8px;
}

.guess {
  background-color: #f6f4f4;
  border-radius: 8px;
}

.hint {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
}

.fill {
  background: green;
  white-space: nowrap;
  width: 100%;
  border-radius: 8px;
  padding: 15px;

  /* visibility: hidden; */
}

.distance-bar {
  border-radius: 8px;
  overflow: scroll;
}

.mile-marker {
  padding-right: 15px;
}
