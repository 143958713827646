.label {
  display: none;
}

.marker:hover .label {
  display: block;
}

.map {
  overflow: hidden;
  cursor: grab;
}

.map:active {
  cursor: grabbing;
}

.map-container {
  position: relative;
}

.zoom-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 1000;
}
